//Dependencies
import React, { useEffect, useState } from "react";
//import axios from "axios";
//import Storage from "../../services/localStorage.service";
import Layout from "../../components/layouts";

// Internationalization
import { useTranslation } from 'react-i18next';

// Styles
import * as thankyouStyles from "./thankyou.module.scss"

// Assets
import { Link } from "gatsby";


const Failed = ({location, pageContext}) => {

    // Translation Function
    const { t } = useTranslation();
    
    useEffect(() => {
        //Get Params
        const params = new URLSearchParams(location.search);
        // const paymentMethod = params.get('centraPaymentMethod');
        // const payload = params.get('payload');
        // const klarnaOrder = params.get('klarna_order');
        // const pmFields = {
        //     centraPaymentMethod: paymentMethod
        // }
        console.log(params);

    }, [])

    return (
        <Layout country={pageContext.country} location={location}>

            <div className={thankyouStyles.cdThankyouPage}>
                <div className="cd-max-width">
                    <div className={thankyouStyles.cdThankyouContainer}>
                        <div className={thankyouStyles.cdThankyouContent}>
                            <div className={thankyouStyles.cdThankyouImage}>
                                <h3>{t('voucher.error')}!</h3>
                            </div>
                            <div className={thankyouStyles.cdThankyouHeading}>
                                <Link to={`/${pageContext.country}/checkout/`}>
                                    <h2>{t('thankYou.goBack')} Checkout</h2>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Failed